import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import Layout from '../../layouts/defaultLayout'
import SEO from '../seo'
import { styles } from '../../pagesStyles/joinStyles'
import JoinCircle from '../joinCircle'
import { Typography, Grid } from '@material-ui/core'
import CHRContentContainer from '../contentContainer'
import CHRButton from '../button'
import birdIcon from '../../assets/images/chirpyest-logo.svg'
import CHROutlinedButton from '../outlinedButton'
import { FONTS } from '../../constants'
// import LeftVideoSection from '../leftVideoSection'
// import RightVideoSection from '../rightVideoSection'
import { navigate } from 'gatsby'
import { ROUTES } from '../../constants'
import joinCircle1 from '../../assets/images/newJoin-1.jpg'
import joinCircle2 from '../../assets/images/newJoin-2.png'
import joinCircle3 from '../../assets/images/newJoin-3.jpg'
import JoinWithEmailModal from '../joinModales/joinWithEmail'
import MainModal from '../joinModales/mainJoin'
import DetailsModal from '../joinModales/details'
// import axios from '../../axios'
import * as moment from 'moment'
import { useLocation } from '@reach/router'
import { isUserAuthenticated } from '../../../utils/isUserAuthenticated'
import BlockUserInterface from '../blockUserInterface'
import PinExtensionReminderPopOut from '../popOuts/pinExtensionReminderPopOut'
import OldJoin from '../../pages/joins'
import { useAppState } from '../../appState'
import SignInModal from './../signInModal'
import { setItemToLocalStorage } from '../../../utils/setLocalStorage'
import Links from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
interface JoinProps {
  t: TFunction
  data: any
}

const referralIdRegex = /(.*-.*)/

const Joins = ({ t, data }: JoinProps) => {
  const classes = styles()
  const [appState] = useAppState()
  const [modalOpened, setModalOpened] = useState(
    window.location.search.includes('popup=1') ? 1 : 0
  )
  const { pathname } = useLocation()
  const referralId = pathname?.split('/')[2]?.split('#')[0] || ''
  const [param] = window.location.search.slice(1).split('&')
  const [isExtensionDownloaded, setIsExtensionDownloaded] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isTermsChecked, setIsTermsChecked] = useState(true)
  const [isNewsletterChecked, setIsNewsletterChecked] = useState(true)
  const [isOpenSignInModal, setIsOpenSignInModal] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  // redirect user to members page instead of join page
  useEffect(() => {
    // if (appState.userId && modalOpened === 0) navigate(ROUTES.member) // Commented because we need to access join page even if we are logged in
  }, [])

  useEffect(() => {
    if (referralId) {
      setItemToLocalStorage(
        'referralId',
        JSON.stringify({ id: referralId, time: moment().format() })
      )
    }

    if (
      param.split('=')[0] === 'fromExtension' &&
      param.split('=')[1] === 'true'
    ) {
      setItemToLocalStorage('extensionDownloaded', 'true')
      isUserAuthenticated().then(result => {
        if (!!result) {
          navigate(ROUTES.member, { state: { showPopUp: true } })
        } else {
          setIsModalOpen(true)
          setIsExtensionDownloaded(true)
          setIsPageLoading(false)
        }
      })
    } else {
      setIsPageLoading(false)
      const isExtensionInstalled = window.localStorage.getItem(
        'extensionDownloaded'
      )
      if (isExtensionInstalled && isExtensionInstalled === 'true') {
        setIsExtensionDownloaded(true)
      }
      return
    }
  }, [])

  const handleCloseModal = () => {
    setModalOpened(0)
    ;(modalOpened === 3 || modalOpened === 4) && navigate(ROUTES.member)
  }

  const nextStep = () => {
    history.pushState(null, '', pathname + '?popup=1')
    setModalOpened((prevState: number) => prevState + 1)
  }

  const goToUserInfoPage = () => {
    navigate(ROUTES.personalInfo)
  }

  const handleNewslettersSwitch = () => {
    setIsNewsletterChecked(!isNewsletterChecked)
  }

  const handleTermsSwitch = () => {
    setIsTermsChecked(!isTermsChecked)
  }

  const closeSignInModal = () => {
    setIsOpenSignInModal(false)
  }

  const isInvite = referralId && referralIdRegex.test(referralId)
  if (isInvite) {
    return <OldJoin t={t} data={data} />
  }

  if (isPageLoading) {
    return <BlockUserInterface />
  }

  return (
    <>
      <Layout>
        <SEO
          title={t('join.metaTitle')}
          description={t('join.metaDescription')}
        />
        <CHRContentContainer>
          <section className={classes.section}>
            <Breadcrumbs aria-label="breadcrumb">
              <Links
                color="inherit"
                rel="preconnect"
                onClick={(
                  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                ) => {
                  event.preventDefault()
                  navigate(ROUTES.home)
                }}
              >
                home
              </Links>
              <Typography color="textPrimary">join chirpyest</Typography>
            </Breadcrumbs>
            <div>
              <Typography variant="h1" className={classes.TopTitle}>
                shop, share, earn cash back!
              </Typography>
            </div>
            <div className={classes.firstRow}>
              <JoinCircle circlelabel="easy" color="#175972">
                shopping tools to help find cashback
              </JoinCircle>
              <JoinCircle
                circlelabel="share"
                color="#F27870"
                customStyle={classes.circle2}
              >
                finds with friends – earn more cash back
              </JoinCircle>
            </div>
            <div className={classes.secondRow}>
              <JoinCircle circlelabel="save" color="#F9BEBF">
                items to your board
              </JoinCircle>
              <JoinCircle
                circlelabel="earn"
                color="#2E7C86"
                customStyle={classes.circle4}
              >
                up to 15% cash back
              </JoinCircle>
              <JoinCircle circlelabel="shop" color="#E33D40">
                1,000’s of top brands
              </JoinCircle>
            </div>
            <CHRButton
              onClick={nextStep}
              label="Join chirpyest - it's free"
              icon={birdIcon}
              customStyle={classes.buttonStyles}
            />
          </section>
          <section className={classes.section}>
            <Typography variant="h1" className={classes.centerText}>
              what is chirpyest?
            </Typography>
            <Grid container spacing={5}>
              <Grid item md={4} className={classes.gridItem}>
                <img
                  src={joinCircle1}
                  alt="join-circle"
                  className={classes.joinCircle}
                />
                <Typography variant="h2">a shopping platform</Typography>
                <Typography variant="subtitle1">
                  a shopping platform where you can shop and earn cash back from
                  top retailers and brands.
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.gridItem}>
                <img
                  src={joinCircle2}
                  alt="join-circle-2"
                  className={classes.joinCircle}
                />
                <Typography variant="h2">a browser extension</Typography>
                <Typography variant="subtitle1">
                  a browser extension that automatically scans the web for cash
                  back wherever you shop!
                </Typography>
              </Grid>
              <Grid item md={4} className={classes.gridItem}>
                <img
                  src={joinCircle3}
                  alt="join-circle-3"
                  className={classes.joinCircle}
                />
                <Typography variant="h2">your shopping board</Typography>
                <Typography variant="subtitle1">
                  your personal shopping board to save items you love to either
                  shop or share and earn cash back
                </Typography>
              </Grid>
              <CHRButton
                onClick={() => navigate(ROUTES.howItWorks)}
                label="learn how it works"
                customStyle={classes.buttonStyles}
              />
            </Grid>
          </section>
        </CHRContentContainer>

        {/* videos section  */}
        {/* <section>
          <LeftVideoSection
            handleArrowClick={() => {}}
            videoSrc={videoSrc}
            rightChildren={
              <div>
                <Typography variant="h2" className={classes.videoSectionTitle}>
                  our shopping platform
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.videoSectionContent}
                >
                  Chirpyest is a shopping platform allowing users to earn cash
                  back from 1000s of brands.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.videoSectionContent}
                >
                  What makes Chirpyest even better is that we allow users to
                  share items they love and earn even more cash back when
                  friends buy.
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.videoSectionContent}
                >
                  It's easy and free!
                </Typography>
              </div>
            }
            buttonLabel="join chirpyest"
          />
        </section>
        <section>
          <RightVideoSection
            handleArrowClick={navigateToStore}
            videoSrc={videoSrc} // TODO: change to videos
            leftChildren={
              <div>
                <Typography variant="h2" className={classes.videoSectionTitle}>
                  our browser extension
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.videoSectionContent}
                >
                  Our browser extension is easy to add and it does all the work
                  for you to find cash back wherever you shop on the web. Once
                  you add it to your tool bar, that's the last bit of work
                  you'll have to do....because we'll let you know when cash back
                  is available where ever you shop.
                </Typography>
              </div>
            }
            buttonLabel="add our extension"
          />
        </section>
        <section>
          <LeftVideoSection
            handleArrowClick={() => {}}
            videoSrc={videoSrc}
            rightChildren={
              <div>
                <Typography variant="h2" className={classes.videoSectionTitle}>
                  your shopping board
                </Typography>
                <Typography
                  variant="subtitle1"
                  component="p"
                  className={classes.videoSectionContent}
                >
                  We are democratizing influence with our shareable shopping
                  board and proprietary share link tool. These allow you to
                  share items you love and earn more cash back when someone who
                  follows you buys!
                </Typography>
              </div>
            }
            buttonLabel="join chirpyest"
          />
        </section> */}

        <CHRContentContainer>
          <section className={classes.centerText}>
            <Typography variant="h1" component="h2">
              so what are you waiting for? let’s go!
            </Typography>
            <CHROutlinedButton
              label="join chirpyest"
              font={FONTS.Graphik.GraphikMedium}
              onClick={nextStep}
            />
          </section>
        </CHRContentContainer>
        <MainModal
          openStatus={modalOpened === 1}
          handleClose={handleCloseModal}
          handleFinish={nextStep}
          onIsTermsChecked={handleTermsSwitch}
          onIsNewsletterChecked={handleNewslettersSwitch}
        />
        <JoinWithEmailModal
          openStatus={modalOpened === 2}
          handleClose={handleCloseModal}
          handleFinish={goToUserInfoPage}
          isExtensionDownloaded={isExtensionDownloaded}
          isTermsCheckedParent={isTermsChecked}
          isNewsletterCheckedParent={isNewsletterChecked}
          redirectToLogin={() => {
            handleCloseModal()
            setIsOpenSignInModal(true)
          }}
        />
        <DetailsModal
          openStatus={modalOpened === 3}
          handleClose={handleCloseModal}
          handleFinish={nextStep}
        />
        <PinExtensionReminderPopOut
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
        <SignInModal
          handleClose={closeSignInModal}
          openStatus={isOpenSignInModal}
          setIsLoggedIn={setIsLoggedIn}
        />
      </Layout>
    </>
  )
}

export default withTranslation()(Joins)
